<template>
  <div class="ProgressReport-wrap row items-center justify-between">
    <!-- 圖表圖片 -->
    <img class="chartIcon" src="../../assets/img/chart_icon.png" alt="chart_icon" />
    <!-- 粉色角色 -->
    <img class="pinkItem" src="../../assets/img/pink_item.png" alt="" />
    <img class="pinkItemLeftHand" src="../../assets/img/pink_item_left_hand.png" alt="" />
    <img class="pinkItemRightHand" src="../../assets/img/pink_item_right_hand.png" alt="" />
    <!-- 白色方框內容 -->
    <div class="container">
      <div class="myTitle">你的平均表現</div>
      <!-- <div class="smallText">顯示所有人平均</div> -->
      <img class="smallText" src="../../assets/img/everyone_average.svg" alt="" />
      <!-- 橫行圖表 -->
      <div class="prgressLineAndType row items-center justify-center">
        <!-- 圖表標題 -->
        <div class="col-3 column items-center justify-between type-wrap">
          <span class="typeTitle" v-for="item in data" :key="item.title">{{ item.title }}</span>
        </div>
        <!-- 百分比 -->
        <div class="col column justify-between prgressLineItem">
          <ProgressLine class="" v-for="item in data" :key="item.title" :itemData="item" />
        </div>
      </div>
      <div class="smallLabel row items-center justify-between">
        <span>原本</span>
        <span>+50點</span>
        <span>+100點</span>
      </div>
    </div>
  </div>
  <!-- title: '覺察力', value: 70, average: 50 -->
</template>

<script>
import ProgressLine from './ProgressLine.vue';
export default {
  name: 'ProgressReport',
  props: ['data'],
  components: { ProgressLine },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.ProgressReport-wrap {
  width: 100%;
  position: relative;
  margin-top: 52px;
  min-height: 187px;
}
.chartIcon {
  position: absolute;
  top: -21px;
  left: 34px;
  z-index: 10;
}
.pinkItem {
  position: absolute;
  top: -43px;
  right: 20px;
}
.pinkItemLeftHand {
  position: absolute;
  top: -20px;
  right: 73px;
  z-index: 10;
}
.pinkItemRightHand {
  position: absolute;
  top: -3px;
  right: 33px;
  z-index: 10;
}
.container {
  position: absolute;
  top: 0;
  left: 5%;
  z-index: 5;
  width: 90.67%;
  min-height: 183px;
  background: #ffffff;
  border-radius: 10px;
  .myTitle {
    font-weight: 500;
    font-size: 24px;
    color: #5c6b9b;
    text-align: center;
    padding: 9px 0 6px 0;
  }
  .smallText {
    // font-size: 5px;
    // text-align: right;
    padding-right: 5px;
    display: flex;
    margin-left: auto;
    margin-bottom: 16px;
  }
  .prgressLineAndType {
    padding-right: 30px;
  }
  .prgressLine-wrap {
    padding-right: 30px;
  }
  .type-wrap {
    border-right: 1px solid #ddd3d3;
    height: 88px;
  }
  .typeTitle {
    color: #6e6fbd;
    padding: 0 10px;
  }
  .prgressLineItem {
    height: 83px;
    padding-top: 5px;
    padding-left: 3px;
  }
  .smallLabel {
    font-size: 7px;
    line-height: 10px;
    color: #808080;
    padding-left: 19%;
    padding-right: 30px;
  }
}
</style>
<style lang="scss"></style>
