<template>
  <div class="FeaturedDialog-wrap">
    <div v-for="(item, index) in data" :key="item.title">
      <!-- 上面區塊 -->
      <div class="container-wrap">
        <!-- 標題 -->
        <div class="myTitle" v-html="item.title"></div>
        <div class="bigText sAnalyze-container" v-html="item.bigText"></div>
        <div class="bigTextDetail">{{ item.bigTextDetail }}</div>
        <div class="disadvantage-wrap">
          <div class="disadvantageTitle">
            對方的缺點:
            <span>「{{ item.disadvantage }}」</span>
            <br />
            我說:
          </div>
          <div class="disadvantageContent">{{ item.content }}</div>
        </div>
        <!-- 播放鍵與暫停鍵 -->
        <q-icon v-if="startAudio === index && playingAudio" class="stopBtn" name="stop" @click="pauseAudioMedia" />
        <img v-else class="playBtn" :class="{ disableBtn: !item.audio }" src="../../assets/img/btn-play-purple.svg" alt="btn-play" @click="startAudioMedia(index, item.audio)" />
      </div>
      <!-- 下面區塊(優勢) -->
      <div class="advantage-wrap row items-center">
        <span class="col-3 advantageTitle">優勢</span>
        <span class="col advantageContent">{{ item.advantage }}</span>
      </div>
    </div>
    <!-- 音檔(不顯示) -->
    <audio v-show="false" :ref="'myAudio'" controls :src="myAudio"></audio>
  </div>
</template>
<script>
export default {
  name: 'FeaturedDialog',
  props: ['data'],
  components: {},
  data() {
    return {
      startAudio: null,
      playingAudio: false,
      // 播放的音檔
      myAudio: null
    };
  },
  mounted() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    // 監聽音檔結束
    myAudio.addEventListener(
      'ended',
      function() {
        self.startAudio = false;
      },
      false
    );
  },
  methods: {
    // 播放音檔
    startAudioMedia(index, audio) {
      const myAudio = this.$refs.myAudio;
      // 如果有其他的音檔正在播，則先關掉，在播新的
      if (this.startAudio !== index) {
        myAudio.pause();
        this.myAudio = null;
        myAudio.currentTime = 0;
      }
      // 若未指定要播放的音檔則就此結束
      if (!audio) {
        return;
      }
      this.startAudio = index;
      this.myAudio = audio;
      this.$nextTick(() => {
        myAudio.play();
        this.playingAudio = true;
      });
    },
    // 暫停音檔
    pauseAudioMedia() {
      const myAudio = this.$refs.myAudio;
      myAudio.pause();
      this.playingAudio = false;
    }
  },
  beforeDestroy() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    myAudio.removeEventListener(
      'ended',
      function() {
        self.startAudio = false;
        self.myAudio = null;
      },
      false
    );
  }
};
</script>
<style scoped lang="scss">
.FeaturedDialog-wrap {
  width: 100%;
  margin: 0 auto;
}
.container-wrap {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 90.67%;
  color: #2d234c;
  margin: 0 auto;
  position: relative;
  margin-bottom: 17px;
}
.myTitle {
  width: 149px;
  height: 24px;
  color: #625f74;
  background: #ecaca7;
  border-radius: 50px;
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -10px;
  padding-top: 3px;
}
.bigText {
  font-size: 18px;
  line-height: 27px;
  color: #ee5f7f;
  text-align: center;
  font-weight: bold;
  padding-top: 21px;
}
.bigTextDetail {
  font-size: 12px;
  line-height: 18px;
  color: #625f74;
  text-align: center;
}
.disadvantage-wrap {
  background-image: url('../../assets/img/pink_half_circle.png');
  background-repeat: no-repeat;
  padding: 14px 59px;
  margin-top: 8px;
  background-size: 100% 123px;
  background-position: bottom;
}
.disadvantageTitle {
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #868686;
  padding: 0 30px 8px 30px;
  span {
    color: #504ea9;
  }
}
@mixin mini_scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e4c8c8;
    border-radius: 7px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e4c8c8;
  }
}
.disadvantageContent {
  height: 58px;
  overflow-y: auto;
  @include mini_scroll;
  color: #321a59;
  margin-right: 10px;
}
.stopBtn {
  // background-color: #c0a279;
  background: radial-gradient(50% 50% at 50% 50%, rgba(48, 36, 190, 0) 0%, #3024be 100%);
  color: #ffffff;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  line-height: 28px;
  font-size: 18px;
  position: absolute;
  bottom: 12px;
  right: 25px;
}
.playBtn {
  position: absolute;
  bottom: 12px;
  right: 25px;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  animation: heartbeat 1.8s infinite;
}
.disableBtn {
  animation: none;
  opacity: 0.4;
}
// 類似心跳的動畫
@keyframes heartbeat {
  0% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px rgba(48, 36, 190, 0.5);
    height: 28px;
    width: 28px;
  }
  50% {
    opacity: 1;
    box-shadow: 0px 0px 3px 3px rgba(48, 36, 190, 0.5);
    height: 32px;
    width: 32px;
  }
  100% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px rgba(48, 36, 190, 0.5);
    height: 28px;
    width: 28px;
  }
}
.advantage-wrap {
  width: 90.67%;
  min-height: 56px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 41px auto;
}
.advantageTitle {
  color: #6e6fbd;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.advantageContent {
  font-size: 12px;
  line-height: 18px;
  padding: 5px;
}
</style>
<style lang="scss"></style>
