<template>
  <div class="NewReportPage-wrap">
    <transition name="fade">
      <!-- 黃色角色 -->
      <img v-if="yellowItemShow" class="yellowItem" src="../../assets/img/yellow_item_right.png" alt="" />
    </transition>
    <transition name="fade">
      <img v-if="yellowItemShow" class="yellowItemHand" src="../../assets/img/yellow_item_right_hand.png" alt="" />
    </transition>
    <!-- 主要內容 -->
    <div class="NewReportPage-container" @scroll="scrollEvent()">
      <!-- 標題 -->
      <ReportPageHeader class="ReportPageHeader-component" title="個人心靈成長報告" back="返回" backTo="/NewMainPage" />
      <!-- 小字引言 -->
      <div class="smallSentence">
        <span class="username" v-text="userName"></span>
        ，
        <br />
        你對問題狀況的覺察力、對負面心境的轉念力、對自我框架的突破力， 又累積了新的成長!
      </div>
      <!-- 能量種子區塊 -->
      <EnergySeed :data="energySeedData" />
      <!-- 本次進展區塊 -->
      <ProgressReport :data="progressReportData" />
      <!-- 精選對話區塊(可展開與收起) -->
      <div class="row items-center justify-between expansionBtn" @click="expanded = !expanded">
        <img :class="expanded ? 'expandedStatue' : ''" src="../../assets/img/double_arrow_down_icon.png" alt="double_arrow_down_icon" />
        <span>{{ expanded ? '收起' : '展開看' }}精選對話</span>
        <img :class="expanded ? 'expandedStatue' : ''" src="../../assets/img/double_arrow_down_icon.png" alt="double_arrow_down_icon" />
      </div>
      <q-expansion-item v-model="expanded">
        <q-card class="expansionCard">
          <FeaturedDialog :data="featuredDialogData" />
        </q-card>
      </q-expansion-item>
      <!-- logo -->
      <img class="logoImg" src="../../assets/img/Voiss_Logo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '../../router/index';
import tool from '../../tool';
import { debounce } from 'quasar';
import audioTest from '../../assets/media/11296260_MotionElements_heaven-piano_preview.mp3';
import ReportPageHeader from '../../components/NewReportPage/ReportPageHeader.vue';
import EnergySeed from '../../components/NewReportPage/EnergySeed.vue';
import FeaturedDialog from '../../components/NewReportPage/FeaturedDialog.vue';
import ProgressReport from '../../components/NewReportPage/ProgressReport.vue';
import { parseContentToHTML, parseAudioLink } from '@/lib/utils';

export default {
  name: 'NewReportPage',
  components: { ReportPageHeader, EnergySeed, FeaturedDialog, ProgressReport },
  data() {
    return {
      // 黃色角色是否顯示
      yellowItemShow: false,
      // 使用者名稱
      userName: 'Xylia',
      // 能量種子顯示資料
      energySeedData: [
        // {
        //   title: '覺察力',
        //   smallText: '看事情以小見大的',
        //   expRate: 50,
        //   level: 1
        // }
      ],
      // 本次進展的資料
      progressReportData: [
        // {
        //   title: '覺察力',
        //   value: 70,
        //   average: 50
        // }
      ],
      // 精選對話的資料
      featuredDialogData: [
        // {
        //   title: '覺察力',
        //   bigText: '高於常人',
        //   bigTextDetail: '你從狀況背後看見了比其他人更細膩的細節!',
        //   disadvantage: '引吃卯糧',
        //   content: '話語話語話語話語話語話語話語話語話語話語話語話語話語話語話語話語話語話語',
        //   audio: audioTest,
        //   advantage: '更高的覺察力，讓你不論處在多麼紛擾的世界，都能抓到重點、見微知著掌握先機，不對任何新機會視而不見!'
        // }
      ],
      //  精選對話是否展開
      expanded: true
    };
  },
  computed: {
    ...mapState({
      reportId: state => state.ReportPageModule.reportId,
      // 個人成長軌跡
      personalData: state => state.ReportPageModule.personalData,
      // 個人成長軌跡 - 跟大家的比較 (那個圖表)
      personalCompare: state => state.ReportPageModule.personalCompare,
      // 個人成長軌跡 - 最底下的句子
      personalSentences: state => state.ReportPageModule.personalSentences
    }),
    pageDataReady() {
      // 目前是設定成全部都要有資料才算準備完畢
      return this.personalData && this.personalCompare && this.personalSentences;
    }
  },
  created() {
    tool.scrollToTop();
  },
  async mounted() {
    if (this.reportId && !this.$route.query.s) {
      let s = this.reportId;
      this.$router.replace(this.$route.path + `?s=${s}`);
    }
    if (!this.pageDataReady) {
      let reportId = this.reportId || this.$route.query.s;
      let reports = ['personal_data', 'personal_compare', 'personal_sentences'];
      await this.updateReportData({ id: reportId, reports });
    } else {
      this.updatePersonalData();
      this.updatePersonalCompare();
      this.updatePersonalSentences();
    }
  },
  watch: {
    personalData(data) {
      this.updatePersonalData(data);
    },
    personalCompare(data) {
      this.updatePersonalCompare(data);
    },
    personalSentences(data) {
      this.updatePersonalSentences(data);
    }
  },
  methods: {
    ...mapActions('ReportPageModule', ['updateReportData']),
    updatePersonalData(data = this.personalData) {
      console.log('update personalData');
      this.userName = data.nickname;
      this.energySeedData = data.items.map(item => {
        return {
          name: item.name,
          title: item.title,
          smallText: parseContentToHTML(item.description),
          img: item.img,
          primaryText: '能量幣：' + item.exp.toFixed(0),
          expRate: item.expRate * 100,
          level: item.level,
          levelText: item.levelText
        };
      });
    },
    updatePersonalCompare(data = this.personalCompare) {
      console.log('update personalCompare');
      this.userName = data.nickname;
      this.progressReportData = data.items.map(item => {
        return {
          name: item.name,
          title: item.title,
          value: item.selfValue,
          average: item.otherValue
        };
      });
    },
    updatePersonalSentences(data = this.personalSentences) {
      console.log('update personalSentences');
      this.userName = data.nickname;
      this.featuredDialogData = data.items.map(item => {
        item.introduction = item.introduction || {};
        return {
          name: item.name,
          title: item.head,
          bigText: parseContentToHTML(item.introduction.title),
          advantage: parseContentToHTML(item.advantageContent),
          bigTextDetail: item.introduction.content,
          disadvantage: item.disadvantage,
          content: item.content,
          audio: parseAudioLink(item.audioSrc)
        };
      });
    },
    // 滾動事件
    scrollEvent() {
      const top = document.querySelector('.NewReportPage-container').scrollTop;
      if (top > 400) {
        this.yellowItemShow = true;
      } else {
        this.yellowItemShow = false;
      }
    },
    // 偵測使用者向哪裡滑的偵測器
    handlePan: debounce(function({ evt, ...info }) {
      const top = document.querySelector('.NewReportPage-container').scrollTop;
      // if (process.env.NODE_ENV === 'development') {
      //   // console.log(evt);
      //   console.log('offsetTop', top);
      // }
      // if (process.env.NODE_ENV === 'development') console.log(info);
      if (info.direction === 'down' && top === 0) {
        router.push('/NewMainPage');
      }
    }, 100)
  }
};
</script>
<style scoped lang="scss">
@mixin mini_scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(182, 183, 205, 1);
    border-radius: 7px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(182, 183, 205, 1);
  }
}
.NewReportPage-wrap {
  background-image: url('../../assets/img/new_bg_item.png');
  height: 100vh;
  max-width: 414px;
  background-repeat: no-repeat;
  background-size: 46%;
  overflow: hidden;
  position: relative;
  // @include mini_scroll;
  // overflow-y: auto;
}
.yellowItem {
  position: absolute;
  right: 0;
  bottom: 70px;
}
.yellowItemHand {
  position: absolute;
  right: 7px;
  bottom: 95px;
  z-index: 10;
}
.NewReportPage-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  @include mini_scroll;
  overflow-y: auto;
  overflow-x: hidden;
}
.ReportPageHeader-component {
  padding: 0 5% 0 10%;
  // height: 64px;
}
.smallSentence {
  color: rgba(0, 0, 0, 0.39);
  font-size: 11px;
  line-height: 16px;
  width: 90.9%;
  margin: 5px auto 20px auto;
  span {
    color: #504ea9;
    font-size: 18px;
  }
}
.expansionBtn {
  width: 234px;
  height: 37px;
  background: #ecaca7;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  margin: 20px auto;
  padding: 0 27px;
  cursor: pointer;
  img {
    // transform: rotate(180deg);
    &.expandedStatue {
      transform: rotate(180deg);
    }
  }
}
.expansionCard {
  padding: 43px 0 116px 0;
  background: none;
}
.logoImg {
  margin: 0 auto;
  display: block;
}
</style>
<style>
.NewReportPage-wrap .q-expansion-item__container .q-item--clickable {
  display: none;
}
</style>
