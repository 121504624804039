<template>
  <div class="EnergySeed-wrap">
    <!-- 標題 -->
    <div class="row items-center justify-center q-pt-sm">
      <img src="../../assets/img/growing_seedlings.png" alt="growing_seedlings" />
      <span class="myTitle">能量種子</span>
    </div>
    <!-- 澆水的圖片 -->
    <img class="wateringBottleImg" src="../../assets/img/watering_bottle.png" alt="watering_bottle" />
    <img class="wateringWaterImg" src="../../assets/img/watering_water.png" alt="watering_water" />
    <!-- 內容(有三欄) -->
    <div class="container row items-center justify-between">
      <div class="containerItem col-4 column items-center justify-center" v-for="(item, index) in tempData" :key="item.title">
        <!-- 種子圖片 -->
        <img class="seedImg q-pb-xs" :src="plantImg(item.img)" alt="seed_status_img" />
        <!-- 等級與文字 -->
        <div>
          <span class="level">Lv {{ item.level }}</span>
          <span class="levelText" v-html="item.levelText"></span>
        </div>
        <div>
          <span class="primaryText" :class="primaryTextClass(index)">{{ item.primaryText }}</span>
        </div>
        <!-- 圓形百分比+圖片 -->
        <q-circular-progress
          show-value
          class="q-mt-md q-mb-sm"
          :value="item.expRate"
          size="85px"
          :thickness="0.1"
          :color="index === 0 ? 'myRed' : index === 1 ? 'myBlue' : 'myGreen'"
          center-color="myPink"
          track-color="transparent"
        >
          <img :src="index === 0 ? progress1 : index === 1 ? progress2 : progress3" alt="progressImg" />
        </q-circular-progress>
        <!-- 小字描述 -->
        <div class="smallText">{{ item.smallText }}</div>
        <!-- 大字標題 -->
        <div class="bigTitle">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import seedImg from '../../assets/img/seed.png';
import seedlingImg from '../../assets/img/seedling.png';
import progress1 from '../../assets/img/magnifier.png';
import progress2 from '../../assets/img/angry.png';
import progress3 from '../../assets/img/bird.png';

const plantImgMap = {
  1: require('@/assets/img/level-plant/1.png'),
  2: require('@/assets/img/level-plant/2.png'),
  3: require('@/assets/img/level-plant/3.png'),
  4: require('@/assets/img/level-plant/4.png'),
  5: require('@/assets/img/level-plant/5.png'),
  6: require('@/assets/img/level-plant/6.png'),
  7: require('@/assets/img/level-plant/7.png'),
  8: require('@/assets/img/level-plant/8.png'),
  9: require('@/assets/img/level-plant/9.png')
};

export default {
  name: 'EnergySeed',
  props: ['data'],
  components: {},
  data() {
    return {
      seedImg,
      seedlingImg,
      progress1,
      progress2,
      progress3,
      tempData: []
    };
  },
  watch: {
    data(data) {
      if (data) {
        this.reset();
      }
    }
  },
  mounted() {
    this.reset();
  },
  computed: {},
  methods: {
    reset() {
      // 資料晚點寫入，才可以製造動畫
      const copyData = JSON.parse(JSON.stringify(this.data));
      copyData.forEach(item => {
        item.expRate = 0;
      });
      this.tempData = copyData;
      setTimeout(() => {
        this.tempData.forEach((item, index) => {
          item.expRate = this.data[index].expRate;
        });
      }, 500);
    },
    plantImg(img) {
      return plantImgMap[img];
    },
    primaryTextClass(i) {
      return {
        'text-myRed': i === 0,
        'text-myBlue': i === 1,
        'text-myGreen': i === 2
      };
    }
  }
};
</script>
<style scoped lang="scss">
.EnergySeed-wrap {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  width: 90.67%;
  margin: 0 auto;
  min-height: 332px;
  position: relative;
}
.myTitle {
  font-weight: 500;
  font-size: 24px;
  color: #5c6b9b;
  margin-left: 7px;
}
.wateringBottleImg {
  margin-left: calc(105% - 68px);
  margin-top: -34px;
  padding-bottom: 34px;
  animation: wateringBottle 1.8s infinite;
}
// 澆水的瓶子的動畫
@keyframes wateringBottle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-24deg);
  }
}
.wateringWaterImg {
  position: absolute;
  right: 40px;
  top: 64px;
  animation: watering 1.8s infinite;
}
// 澆水的水的動畫
@keyframes watering {
  0% {
    right: 40px;
    top: 64px;
  }
  100% {
    top: 96px;
    right: 59px;
  }
}
.container {
  background-image: url('../../assets/img/seedling_bg.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 219px;
  min-height: 200px;
  padding-bottom: 4px;
}
@media (max-width: 359px) {
  .container {
    background-size: 100% 226px;
    padding-bottom: 6px;
  }
  .smallFlowerImg {
    right: 110px;
    top: 69px;
  }
  .smallFlowerImg_1 {
    right: 126px;
    top: 49px;
  }
}
.containerItem {
  margin-top: -18px;
  text-align: center;
}
.seedImg {
  object-position: bottom;
  height: 37px;
  // object-fit: none;
}
.level {
  font-weight: 500;
  padding-right: 4px;
}
.levelText {
  font-size: 8px;
}
.smallText {
  font-weight: 500;
  font-size: 8px;
  color: #464646;
  line-height: 22px;
}
.bigTitle {
  font-weight: bold;
  font-size: 18px;
}
</style>
<style lang="scss">
.EnergySeed-wrap .q-circular-progress {
  transition: 1.5s all ease;
}
</style>
