import { render, staticRenderFns } from "./NewReportPage.vue?vue&type=template&id=bbbdf10e&scoped=true&"
import script from "./NewReportPage.vue?vue&type=script&lang=js&"
export * from "./NewReportPage.vue?vue&type=script&lang=js&"
import style0 from "./NewReportPage.vue?vue&type=style&index=0&id=bbbdf10e&scoped=true&lang=scss&"
import style1 from "./NewReportPage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbbdf10e",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QCard,QItem});
