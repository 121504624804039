<template>
  <div class="ProgressLine-wrap">
    <!-- 背景灰線 -->
    <div class="bgLine"></div>
    <!-- 主要紫線 -->
    <div class="mainLine" :style="`width:${data ? data.value : 0}%`"></div>
    <!-- 旗子 -->
    <img class="flagImg" :style="`left:calc(${data ? data.value : 0}% - 2px)`" src="../../assets/img/flags.png" alt="" />
    <!-- 平均線 -->
    <div class="average" :style="`left:${data ? data.average : 0}%`"></div>
    <!-- 平均文字 -->
    <img class="averageText" :style="`left:calc(${data ? data.average : 0}% - 5px)`" src="../../assets/img/average_text.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'ProgressLine',
  props: ['itemData'],
  components: {},
  data() {
    return {
      data: null
    };
  },
  created() {
    setTimeout(() => {
      // 資料晚點寫入，才可以製造動畫
      this.data = this.itemData;
    }, 500);
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.ProgressLine-wrap {
  width: 100%;
  position: relative;
  height: 10px;
}
.mainLine {
  height: 5.75px;
  background: #9798da;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  width: 0%;
  transition: 1s all ease;
}
.flagImg {
  position: absolute;
  top: -9px;
  left: 0;
  transition: 1s all ease;
}
.bgLine {
  opacity: 0.3;
  border-top: 0.5px solid #000000;
  width: 100%;
  position: absolute;
  top: 2.5px;
}
.average {
  border-left: 2px solid #000000;
  position: absolute;
  top: -3px;
  height: 11px;
  z-index: 10;
  left: 0;
  transition: 1s all ease;
}
.averageText {
  font-size: 5px;
  position: absolute;
  top: -18px;
  z-index: 10;
  left: 0;
  transition: 1s all ease;
}
</style>
<style lang="scss"></style>
