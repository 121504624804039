<template>
  <div class="ReportPageHeader-wrap row items-center justify-between">
    <div class="headerTitle-wrap col-9 hover back-link" v-if="back" @click="clickBack">
      <img src="../../assets/img/chevron_up.png" alt="arrow_up_icon" />
      <span class="headerTitle">{{ back }}</span>
    </div>
    <div class="headerTitle-wrap col-9">
      <span class="headerTitle">{{ title }}</span>
    </div>
    <img class="headerImage" :class="headerImageExtraClass" src="../../assets/img/Voiss_Logo.png" alt="logo" />
  </div>
</template>

<script>
export default {
  name: 'ReportPageHeader',
  props: ['title', 'back', 'backTo'],
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    headerImageExtraClass() {
      if (this.back) {
        return ['headerImage-shift'];
      } else {
        return [];
      }
    }
  },
  methods: {
    clickBack() {
      if (this.backTo) {
        this.$router.replace(this.backTo);
      } else {
        this.$router.back();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.ReportPageHeader-wrap {
  width: 100%;
}
.headerTitle-wrap {
  text-align: center;
  padding-top: 16px;
}
.headerTitle {
  color: #a38686;
  font-weight: bold;
  font-size: 20px;
  padding-left: 15px;
}
.back-link {
  margin-bottom: 16px;
}
.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}
.headerImage {
  position: relative;
}

.headerImage-shift {
  top: -25px;
}
</style>
